<template>
  <auth-layout :image="verify">
    <v-card flat :max-width="500" :width="500" :loading="loading">
      <v-progress-linear
        :value="verificationCompleteProgress"
        v-if="verificationComplete"
      />
      <div class="pa-6">
        <div class="d-flex" v-if="verificationComplete">
          <div>
            <div class="text-h4">{{ $t('auth.verficiationComplete') }}</div>
            <div class="text-h6">
              {{ $t('auth.verficiationCompleteMessage') }}
            </div>
          </div>
        </div>

        <div v-else>
          <v-card-title class="texPrimary--text">{{
            $t('auth.verification')
          }}</v-card-title>
          <v-form @submit.prevent="onSubmit">
            <v-card-text>
              <cz-input
                v-model="code"
                class="text-h5 centered-input"
                outlined
                :placeholder="$t('auth.verificationCodeHint')"
                :max-length="6"
              />
            </v-card-text>
            <v-fade-transition>
              <v-alert dense type="error" v-if="verificationFailed">{{
                $t('auth.verificationFailedMessage')
              }}</v-alert>
            </v-fade-transition>
            <v-card-actions>
              <div class="d-flex align-center flex-column full-width">
                <v-btn
                  color="primary"
                  x-large
                  type="submit"
                  :loading="loading"
                  class="full-width font-weight-semibold"
                  :disabled="code?.length < 6 || resendCode"
                  >{{ $t('auth.verify') }}</v-btn
                >

                <v-btn
                  v-if="showResendVerificationCode"
                  text
                  color="primary"
                  class="mt-3 font-weight-semibold"
                  @click="resendVerificationCode"
                  :loading="resendCode"
                  >{{ $t('auth.reverify') }}</v-btn
                >
              </div>
            </v-card-actions>
          </v-form>
        </div>
      </div>
    </v-card>
  </auth-layout>
</template>

<script>
import bg from '@/assets/background-pattern.jpg';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { CzFormField, CzInput } from '@/components';
import { verifyUser, resendVerificationCode } from '@/core/api';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { addMinutes, isAfter } from 'date-fns';
import { mdiCheckDecagram } from '@mdi/js';
import verify from '@/assets/illustrations/verify.svg';

const nextVerificationStorageKey = 'next-verification';

export default {
  name: 'VendorSignUp',
  components: {
    AuthLayout,
    CzFormField,
    CzInput
  },
  computed: {
    ...mapFields('auth', ['currentUser'])
  },
  created() {
    this.handleResendVerification();
  },
  data() {
    return {
      bg,
      verify,
      mdiCheckDecagram,
      loading: false,
      resendCode: false,
      showResendVerificationCode: false,
      verificationCompleteProgress: 0,
      verificationComplete: false,
      verificationFailed: false,
      code: ''
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage']),
    async onSubmit() {
      try {
        this.loading = true;
        await verifyUser(this.currentUser.email, this.code);
        this.currentUser = {
          ...this.currentUser,
          isVerified: true
        };
        this.handleVerificationComplete();
      } catch (error) {
        this.code = '';
        this.verificationFailed = true;
        setTimeout(() => {
          this.verificationFailed = false;
        }, 3500);
      } finally {
        this.loading = false;
      }
    },
    async resendVerificationCode() {
      try {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.resendCode = true;
        await resendVerificationCode(this.currentUser.email);
        this.showSuccessToastMessage(this.$t('auth.verificationCodeSent'));
        // store the next time user can use the re-verification
        const nextVerificationDate = addMinutes(new Date(), 1);
        window.localStorage.setItem(
          nextVerificationStorageKey,
          nextVerificationDate
        );
        this.showResendVerificationCode = false;
        this.timeoutId = setTimeout(() => {
          this.showResendVerificationCode = true;
        }, 60 * 1000);
      } finally {
        this.resendCode = false;
      }
    },
    handleResendVerification() {
      // read the next verification date from the local storage
      const nextVerificationDate = new Date(
        localStorage.getItem(nextVerificationStorageKey)
      );

      // if local storage is empty or the current date
      // is after the next verification date then we can display
      // the resend verification button
      if (!nextVerificationDate || isAfter(new Date(), nextVerificationDate)) {
        this.showResendVerificationCode = true;
        localStorage.removeItem(nextVerificationStorageKey);
      } else {
        this.showResendVerificationCode = false;
        this.timeoutId = setTimeout(() => {
          this.showResendVerificationCode = true;
        }, 60 * 1000);
      }
    },
    handleVerificationComplete() {
      setTimeout(() => {
        this.verificationComplete = true;
        this.verificationCompleteIntervalId = setInterval(() => {
          this.verificationCompleteProgress += 2;
        }, 50);
      }, 250);
    }
  },
  watch: {
    verificationCompleteProgress: {
      handler(value) {
        if (value === 100) {
          clearInterval(this.verificationCompleteIntervalId);
          setTimeout(() => {
            this.$router.replace({
              name: 'home'
            });
          }, 500);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .centered-input input {
  text-align: center !important;
}
</style>
